import { ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Box, Input, Text, useColorModeValue, Select, Table, Tbody, Td, Th, Thead, Tr, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Compliance, { AssetView } from "./Compliance";
import routes from "routes";
import { toast } from "react-toastify";
import { finalURL } from "api/DataEP";
export default VendorList;

function VendorList(props: { setPageState: React.Dispatch<React.SetStateAction<string>> }) {
    const [userinfoData] = useState(JSON.parse(JSON.stringify(routes[0].userinfo).replace(/"\s+|\s+"/g, '"')));
    const token = userinfoData.token;
    const [searchInput, setSearchInput] = useState("");
    const [AssetViewData, setViewAssetData] = useState<AssetView[] | null>([]);
    const [selectedVendor, setSelectedVendor] = useState("");
    const uniqueVendors = [...new Set(AssetViewData.map((asset) => asset.vendor))];
    const bg = useColorModeValue("white", "#1B254B");
    const filteredSearchData = AssetViewData.filter((asset) =>
        Object.values(asset).some((val) => String(val).toLowerCase().includes(searchInput.toLowerCase()))
    );
    const [currAsset, setCurrAsset] = useState<string | null>(null);
    const handleSearchRowClick = (asset: string) => {
        setCurrAsset(asset);
    };
    useEffect(() => {
        if (
            localStorage.getItem("VENDORselectedVendor") !== selectedVendor &&
            selectedVendor === null &&
            localStorage.getItem("VENDORselectedVendor") !== ""
        ) {
            setCurrAsset(localStorage.getItem("VENDORselectedVendor"));
        }
        if (localStorage.getItem("VENDORsearchInput") !== searchInput && searchInput === null && localStorage.getItem("VENDORsearchInput") !== "") {
            setCurrAsset(localStorage.getItem("VENDORsearchInput"));
        }
        fetchAssetViewData();
    }, []);
    useEffect(() => {
        localStorage.setItem("VENDORselectedVendor", selectedVendor || "");
    }, [currAsset]);
    useEffect(() => {
        localStorage.setItem("VENDORsearchInput", searchInput || "");
    }, [searchInput]);
    const fetchAssetViewData = async () => {
        const response = await fetch(finalURL + "api/compliance/assets", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });

        if (!response.ok) {
            toast.error("Error fetching data");
            return;
        }

        const assets: AssetView[] = await response.json();
        setViewAssetData(assets);
    };

    const downloadCSV = () => {
        const filteredAssets = AssetViewData.filter((asset) => asset.vendor === selectedVendor || selectedVendor === "");
        const csvRows = [];

        // headers
        const headers = Object.keys(filteredAssets[0]);
        csvRows.push(headers.join(","));

        // data
        for (const asset of filteredAssets) {
            const values = headers.map((header) => (asset as any)[header]);
            csvRows.push(values.join(","));
        }

        // form final string
        const csvString = "\uFEFF" + csvRows.join("\n");

        // download file
        const blob = new Blob([csvString], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.setAttribute("hidden", "");
        a.setAttribute("href", url);
        a.setAttribute("download", "asset_list.csv");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };
    const totalFilteredAssets = filteredSearchData.filter((asset) => asset.vendor === selectedVendor || selectedVendor === "").length;
    return (
        <div>
            <Box padding={"5px"}>
                <Button
                    onClick={() => {
                        props.setPageState("home");
                    }}>
                    Document Home
                </Button>
                <ChevronRightIcon />
                <Button
                    onClick={() => {
                        props.setPageState("VendorList");
                        setCurrAsset(null);
                    }}>
                    Vendor List
                </Button>
                <ChevronRightIcon />
            </Box>
            {currAsset ? (
                <>
                    <Compliance fromVendor={true} editMode={false} setPageState={props.setPageState} assetSearch={currAsset} />
                </>
            ) : (
                <>
                    <div>
                        <>
                            <Flex direction="row" align="center" justify="space-around">
                                <Flex direction="row" justify={"flex-start"}>
                                    Vendor Filter
                                    <Select
                                        padding={"5px"}
                                        placeholder="All vendors"
                                        value={selectedVendor}
                                        onChange={(event) => setSelectedVendor(event.target.value)}
                                        maxW="300px"
                                        bg={bg}>
                                        {uniqueVendors.map((vendor) => (
                                            <option key={vendor} value={vendor}>
                                                {vendor}
                                            </option>
                                        ))}
                                    </Select>
                                </Flex>
                                <Button onClick={downloadCSV}>Download the list as a CSV</Button>
                                <Text fontWeight="bold" bg={bg}>
                                    Total Assets: {totalFilteredAssets}
                                </Text>
                            </Flex>
                            <Box bg={bg} padding={"5px"}>
                                <Input
                                    placeholder="Search"
                                    value={searchInput}
                                    onChange={(event) => setSearchInput(event.target.value)}
                                    bg={bg}
                                    padding={"px"}
                                />
                                {totalFilteredAssets === 0 && <Text size="lg">Please Wait</Text>}
                                {totalFilteredAssets !== 0 && (
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>Asset</Th>
                                                <Th>Serial Number</Th>
                                                <Th>Local ID</Th>
                                                <Th>Theme</Th>
                                                <Th>Vendor</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {filteredSearchData
                                                .filter((asset) => asset.vendor === selectedVendor || selectedVendor === "")
                                                .map((asset) => (
                                                    <Tr key={asset.asset} onClick={() => handleSearchRowClick(asset.asset)}>
                                                        <Td>{asset.asset}</Td>
                                                        <Td>{asset.serialNumber}</Td>
                                                        <Td>{asset.localId}</Td>
                                                        <Td>{asset.theme}</Td>
                                                        <Td>{asset.vendor}</Td>
                                                    </Tr>
                                                ))}
                                        </Tbody>
                                    </Table>
                                )}
                            </Box>
                        </>
                    </div>
                </>
            )}
        </div>
    );
}
