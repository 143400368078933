import { Box, Button, Flex, Text, SimpleGrid, Link, useColorModeValue, Image } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import routes from "routes";
import Card from "components/card/Card";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import { toast, ToastContainer } from "react-toastify";

import { IEquipmentService } from "interface/IEquipmentService";
import { IActivity } from "interface/IActivity";
import pdfImg from "../../assets/img/documents/pdf.png";
import { async } from "q";
import { IEquipParams } from "interface/IEquipParams";
import Compliance from "./Compliance/Compliance";
import ComplianceEditLock from "./Compliance/ComplianceEditLock";
import { ChevronRightIcon } from "@chakra-ui/icons";
import VendorList from "./Compliance/VendorList";
import AddVendorTheme from "./Compliance/AddVendorTheme";
import AddTheme from "./Compliance/AddTheme";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function UserReports() {
    const [userinfoData] = useState(JSON.parse(JSON.stringify(routes[0].userinfo).replace(/"\s+|\s+"/g, '"')));
    const token = userinfoData.token;

    let menuBg = useColorModeValue("white", "navy.800");
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const textColor = useColorModeValue("secondaryGray.900", "white");

    // const storedServices = JSON.parse(localStorage.getItem("services"));
    let [pageState, setPageState] = useState("default");
    const [pdfData, setPdfData] = useState<Uint8Array | null>(null);

    function beautifyDateTime(dateTimeString: string): string {
        const options: Intl.DateTimeFormatOptions = {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            second: "numeric",
            hour12: true,
        };
        return new Date(dateTimeString).toLocaleString(undefined, options);
    }

    let currentStatus = -1;
    const [equipData, setequipData] = useState([]);
    const [tableData, settableData] = useState([]);
    const [compDocData, setcompDocData] = useState([]);
    const [searchField, setsearchField] = useState("");
    const [searchTextmsg, setsearchTextmsg] = useState("Search RESULT");
    const [uploadedDocData, setuploadedDocData] = useState([]);
    const [erroState, setErroState] = useState(false);
    const [blankPdfData, setBlankPdfData] = useState<Uint8Array | null>(null);
    const [equipsearchparam, setequipsearchparam] = useState<IEquipParams>({
        asset: null,
        vendor: null,
        theme: null,
        localid: null,
        serialNumber: null,
        docType: null,
        docName: null,
        userName: null,
        hasFile: null,
    });
    const [hasfileonly, sethasfileonly] = useState(true);

    window.addEventListener(
        "touchstart",
        function () {
            // todo some logic
        },
        { passive: false }
    );

    const [editMode, setEditMode] = useState(false);
    useEffect(() => {
        if (userinfoData.role === "ADMIN" || userinfoData.role === "MANAGER") {
            setEditMode(true);
        }
        if (pageState === "default" || pageState === "home") {
            localStorage.clear();
        }
    }, []);

    return (
        <>
            <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
                {pageState === "default" && (
                    <>
                        <Text color={textColor} fontSize="22px" fontWeight="700" lineHeight="100%">
                            Documents
                        </Text>
                        <Flex>
                            <Link
                                onClick={() => {
                                    setPageState("home");
                                }}>
                                <Card alignContent={"center"} padding={"5px"} maxWidth={{ base: "800px", sm: "500px" }} variant="outline">
                                    <Image mx="auto" align={"center"} objectFit="cover" maxW={{ base: "300px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        marginTop="10px"
                                        style={{ whiteSpace: "normal" }}
                                        maxW={{ base: "300px", sm: "180px" }}
                                        onClick={() => {
                                            setPageState("home");
                                        }}>
                                        American Counsulting Group
                                    </Button>
                                </Card>
                            </Link>
                            <Link
                                onClick={() => {
                                    setPageState("home");
                                }}>
                                <Card alignContent={"center"} padding={"5px"} maxWidth={{ base: "800px", sm: "500px" }} variant="outline">
                                    <Image mx="auto" align={"center"} objectFit="cover" maxW={{ base: "300px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        marginTop="10px"
                                        style={{ whiteSpace: "normal" }}
                                        maxW={{ base: "300px", sm: "180px" }}
                                        onClick={() => {
                                            setPageState("home");
                                        }}>
                                        Speaking Rock
                                    </Button>
                                </Card>
                            </Link>
                            <Link
                                onClick={() => {
                                    setPageState("NotFound");
                                }}>
                                <Card alignContent={"center"} padding={"5px"} maxWidth={{ base: "800px", sm: "500px" }} variant="outline">
                                    <Image mx="auto" align={"center"} objectFit="cover" maxW={{ base: "300px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        marginTop="10px"
                                        style={{ whiteSpace: "normal" }}
                                        maxW={{ base: "300px", sm: "180px" }}
                                        onClick={() => {
                                            setPageState("NotFound");
                                        }}>
                                        Other A
                                    </Button>
                                </Card>
                            </Link>
                            <Link
                                onClick={() => {
                                    setPageState("NotFound");
                                }}>
                                <Card alignContent={"center"} padding={"5px"} maxWidth={{ base: "800px", sm: "500px" }} variant="outline">
                                    <Image mx="auto" align={"center"} objectFit="cover" maxW={{ base: "300px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        marginTop="10px"
                                        style={{ whiteSpace: "normal" }}
                                        maxW={{ base: "300px", sm: "180px" }}
                                        onClick={() => {
                                            setPageState("NotFound");
                                        }}>
                                        Other B
                                    </Button>
                                </Card>
                            </Link>
                        </Flex>
                    </>
                )}
                {pageState === "NotFound" && (
                    <>
                        <Button
                            onClick={() => {
                                setPageState("default");
                            }}
                            padding="10px">
                            Go Back
                        </Button>
                        <Text padding="20px">No documents found</Text>
                    </>
                )}
                {pageState === "home" && (
                    <>
                        <Button
                            onClick={() => {
                                setPageState("default");
                            }}
                            padding="10px">
                            Go Back
                        </Button>

                        <Flex padding="20px">
                            <Link
                                onClick={() => {
                                    setPageState("ComplianceSearch");
                                }}>
                                <Card
                                    alignContent={"center"}
                                    padding={"10px"}
                                    maxWidth={{ base: "200px", sm: "200px" }}
                                    overflow="hidden"
                                    variant="outline">
                                    <Image alignContent={"center"} objectFit="cover" maxW={{ base: "200px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        borderRadius="30px"
                                        marginTop="10px"
                                        onClick={() => {
                                            setPageState("ComplianceSearch");
                                        }}>
                                        Compliance
                                    </Button>
                                </Card>
                            </Link>
                            <Link
                                onClick={() => {
                                    setPageState("AddVendorTheme");
                                }}>
                                <Card
                                    alignContent={"center"}
                                    padding={"10px"}
                                    maxWidth={{ base: "200px", sm: "200px" }}
                                    overflow="hidden"
                                    variant="outline">
                                    <Image alignContent={"center"} objectFit="cover" maxW={{ base: "200px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        borderRadius="30px"
                                        marginTop="10px"
                                        onClick={() => {
                                            setPageState("AddVendorTheme");
                                        }}>
                                        Add Vendor
                                    </Button>
                                </Card>
                            </Link>
                            <Link
                                onClick={() => {
                                    setPageState("AddTheme");
                                }}>
                                <Card
                                    alignContent={"center"}
                                    padding={"10px"}
                                    maxWidth={{ base: "200px", sm: "200px" }}
                                    overflow="hidden"
                                    variant="outline">
                                    <Image alignContent={"center"} objectFit="cover" maxW={{ base: "200px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        borderRadius="30px"
                                        marginTop="10px"
                                        onClick={() => {
                                            setPageState("AddVendorTheme");
                                        }}>
                                        Add Theme
                                    </Button>
                                </Card>
                            </Link>
                            <Link
                                onClick={() => {
                                    setPageState("VendorList");
                                }}>
                                <Card
                                    alignContent={"center"}
                                    padding={"10px"}
                                    maxWidth={{ base: "200px", sm: "200px" }}
                                    overflow="hidden"
                                    variant="outline">
                                    <Image alignContent={"center"} objectFit="cover" maxW={{ base: "200px", sm: "100px" }} src={pdfImg} />

                                    <Button
                                        colorScheme="green"
                                        size="md"
                                        borderRadius="30px"
                                        marginTop="10px"
                                        onClick={() => {
                                            setPageState("VendorList");
                                        }}>
                                        Vendor List
                                    </Button>
                                </Card>
                            </Link>
                        </Flex>
                    </>
                )}
                {pageState === "AddVendorTheme" && (
                    <>
                        <AddVendorTheme setPageState={setPageState} />
                    </>
                )}
                {pageState === "AddTheme" && (
                    <>
                        <AddTheme setPageState={setPageState} />
                    </>
                )}
                {pageState === "work" && <></>}
                {pageState === "ComplianceEditLock" && <></>}
                {pageState === "ComplianceSearch" && (
                    <>
                        <Compliance setPageState={setPageState} editMode={editMode} />
                    </>
                )}
                {pageState === "VendorList" && (
                    <>
                        <VendorList setPageState={setPageState} />
                    </>
                )}
            </Box>
        </>
    );
}
