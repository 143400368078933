import { Icon } from "@chakra-ui/react";
import {
    MdBarChart,
    MdClose,
    MdQueryStats,
    MdPerson,
    MdHome,
    MdLock,
    MdGames,
    MdEditDocument,
    MdAddTask,
    MdOutlineAirplaneTicket,
    MdAirplaneTicket,
    MdDepartureBoard,
    MdWarehouse,
    MdMiscellaneousServices,
    MdInventory,
    MdOutlineFileOpen,
    MdOutlineDownloadDone,
    MdOutlineFolderOpen,
} from "react-icons/md";

// Admin Imports
import Document from "views/documents";
import SystemMessage from "views/system";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import { Cookies, useCookies } from "react-cookie";

let userinfoData = getEmpData("empData");

// const userinfoRole = localStorage.getItem("empRole");
// const usrdata = localStorage.getItem("empData");
// const token = localStorage.getItem("token");

function getEmpData(cname: any) {
    let resp;
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    if (ca.length > 0 && ca[0].indexOf("email") > 0) {
        resp = ca;
    } else {
        return "";
    }
    return JSON.parse(resp.toLocaleString().substring(8));
}

var routes = [
    {
        name: "ERROR CONTACT ADMIN",
        layout: "/system",
        path: "",
        icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
        component: SystemMessage,
        userinfo: "",
        userToken: "",
    },
    {
        name: "Sign In",
        layout: "/",
        path: "",
        icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
        component: SignInCentered,
        userinfo: "",
        userToken: "",
    },
];

if (userinfoData !== "") {
    // if (userinfoRole === "ADMIN" || userinfoRole === "MANAGER") {
    if (userinfoData.role === "ADMIN" || userinfoData.role === "MANAGER") {
        routes = [
            {
                name: "View Documents",
                layout: "/documents",
                path: "",
                icon: <Icon as={MdEditDocument} width="20px" height="20px" color="inherit" />,
                component: Document,
                userinfo: userinfoData,
                userToken: userinfoData.token,
            },
            {
                name: "Sign In",
                layout: "/",
                path: "",
                icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
                component: SignInCentered,
                userinfo: userinfoData,
                userToken: userinfoData.token,
            },
        ];
    } else if (userinfoData.role === "SUPERVISOR") {
        routes = [
            {
                name: "Documents",
                layout: "/documents",
                path: "",
                icon: <Icon as={MdEditDocument} width="20px" height="20px" color="inherit" />,
                component: Document,
                userinfo: userinfoData,
                userToken: userinfoData.token,
            },
            {
                name: "Sign In",
                layout: "/",
                path: "",
                icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
                component: SignInCentered,
                userinfo: userinfoData,
                userToken: userinfoData.token,
            },
        ];
    } else if (userinfoData.role === "TECHNICIAN") {
        routes = [
            {
                name: "Documents",
                layout: "/documents",
                path: "",
                icon: <Icon as={MdEditDocument} width="20px" height="20px" color="inherit" />,
                component: Document,
                userinfo: userinfoData,
                userToken: userinfoData.token,
            },
            {
                name: "Sign In",
                layout: "/",
                path: "",
                icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
                component: SignInCentered,
                userinfo: userinfoData,
                userToken: userinfoData.token,
            },
        ];
    }
}

export default routes;
