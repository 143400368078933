import React from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";

import AuthLayout from "./layouts/auth";


import Document from "layouts/documents";

ReactDOM.render(
    <ChakraProvider theme={theme}>
        <React.StrictMode>
            <Router basename={process.env.PUBLIC_URL}>
                <Switch>
                    <Route exact path="/" component={AuthLayout} />
                    <Route path="/documents" component={Document} />
                </Switch>
            </Router>
        </React.StrictMode>
    </ChakraProvider>,
    document.getElementById("root")
);
