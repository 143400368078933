// debug 
// const serviceurl = "https://localhost:7114/api";
// live
import URL from "./URL.json";
export let awsMode = 1;
let serviceurl =URL.LocalURL;
if (awsMode === 1) {
    serviceurl = URL.AwsURL;
}
export const finalURL = serviceurl;
export const EndPoint = {       
    Login:                                         serviceurl + "api/mockup/login", 
    LogOff:                                        serviceurl + "api/mockup/logout", 
}
