import { useEffect, useState, useContext } from "react";
import { useCookies } from "react-cookie";


// Chakra imports
import {
    Avatar,
    Box,
    Flex,
    Input,
    FormLabel,
    Icon,
    Select,
    SimpleGrid,
    useColorModeValue,
    Text,
    Button,
  } from "@chakra-ui/react";

export default function UserReports() {
    
  const [cookies] = useCookies(["empData"]);
  const [userinfoData] = useState(
    JSON.parse(JSON.stringify(cookies.empData).replace(/"\s+|\s+"/g, '"'))
  );

// const userinfo = localStorage.getItem("useremail");
// const userinfoRole = localStorage.getItem("empRole");
// const userinfoName = localStorage.getItem("empName");
// const userinfoPh = localStorage.getItem("phone");

return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <h1>
            ERROR ON YOUR PROFILE CONTACT ADMIN.
        </h1>
      </Box>
    </>
  );
}
