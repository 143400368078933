import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    Input,
    ButtonGroup,
    Checkbox,
    useColorModeValue,
    Flex,
    Button,
    Center,
    Table,
    GridItem,
    Tbody,
    Grid,
    Td,
    Th,
    Thead,
    Tr,
    border,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
} from "@chakra-ui/react";
import {
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverArrow,
    PopoverCloseButton,
    PopoverAnchor,
} from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
import { finalURL } from "api/DataEP";
import routes from "routes";
import { ThemeDetails, ThemeList, VendorList, VendorDetails } from "./Compliance";
import { ChevronDownIcon, ChevronRightIcon } from "@chakra-ui/icons";
interface ThemeData {
    vendorId: string;
    name: string;
}
function AddTheme({ setPageState }: { setPageState: React.Dispatch<React.SetStateAction<string>> }) {
    const [themeList, setThemeList] = useState<ThemeDetails[] | null>(null);
    const [userinfoData] = useState(JSON.parse(JSON.stringify(routes[0].userinfo).replace(/"\s+|\s+"/g, '"')));
    const token = userinfoData.token;
    const [vendorList, setVendorList] = useState<VendorDetails[] | null>(null);
    const [fadingItems, setFadingItems] = useState<{ [key: number]: Boolean }>({});

    const handleRemoval = (index: number) => {
        setFadingItems({ [index]: true });
        setTimeout(() => {
            setAddedTheme(addedTheme.filter((x, i) => i !== index));
            setFadingItems((prev) => {
                const newState = { ...prev };
                delete newState[index];
                return newState;
            });
        }, 500);
    };

    useEffect(() => {
        ThemeList(token, setThemeList);
        VendorList(token, setVendorList);
    }, []);
    async function RefreshData() {
        setThemeList(null);
        await ThemeList(token, setThemeList);
    }

    const [theme, setTheme] = useState("");
    const [addedTheme, setAddedTheme] = useState<ThemeData[]>([]);
    const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
    };
    function handleDone(setFunction: any) {
        if (setFunction === setTheme) {
            setAddedTheme([...addedTheme, { vendorId: "", name: theme }]);
        }
    }
    function handleAdd(vendorID: string, themeName: string) {
        //find added theme and update vendorID without using temp array
        setAddedTheme(
            addedTheme.map((theme) => {
                if (theme.name === themeName) {
                    return { vendorId: vendorID, name: themeName };
                } else {
                    return theme;
                }
            })
        );
    }
    const [searchTerm, setSearchTerm] = useState<String[]>([""]);
    const handleSubmit = () => {
        let URLTheme = finalURL + "api/theme";

        for (let i = 0; i < addedTheme.length; i++) {
            if (addedTheme[i].vendorId === "") {
                toast.error("Vendor not selected for Theme:" + addedTheme[i].name);
                return;
            }
        }
        for (let i = 0; i < addedTheme.length; i++) {
            fetch(URLTheme, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: token,
                },
                body: JSON.stringify(addedTheme[i]),
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.success === false) {
                        toast.error("Theme:" + addedTheme[i].name + " ERROR:\n" + response.message);
                        console.log(response.message);
                    } else {
                        toast.success("Theme:" + addedTheme[i].name + " added successfully");
                    }
                });
        }
    };

    return (
        <div>
            <ToastContainer />
            <Button
                onClick={() => {
                    setPageState("home");
                }}>
                Home
            </Button>
            <ChevronRightIcon />
            <br />
            <br />

            <div>
                <Grid templateColumns="repeat(3, 1fr)" gap={3}>
                    <GridItem colSpan={1}>
                        <Input
                            style={{ border: "1px solid blue", padding: "4px" }}
                            placeholder="Add Theme"
                            type="text"
                            value={theme}
                            fontSize={"lg"}
                            onChange={handleThemeChange}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    if (theme === "") {
                                        toast.error("Theme cannot be empty");
                                    } else {
                                        handleDone(setTheme);
                                        setTheme("");
                                    }
                                }
                            }}
                        />
                        <Box style={{ maxHeight: "430px", overflowY: "scroll" }}>
                            {addedTheme?.map((theme, index) => (
                                // border blue if vendor is selected, red if not
                                <Box
                                    style={{
                                        border: fadingItems[index] ? "1px solid black" : theme.vendorId === "" ? "1px solid red" : "1px solid blue",
                                        padding: "4px",
                                        marginTop: "5px",
                                        marginRight: addedTheme.length > 4 ? "7px" : "0px",
                                    }}>
                                    <div style={fadingItems[index] ? { opacity: 0, transition: "opacity 0.5s ease-out" } : null}>
                                        <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                                            {theme.name}

                                            <GridItem colSpan={1}>
                                                {/* rightmost side */}
                                                <Button
                                                    // alight to right
                                                    style={{ float: "right" }}
                                                    onClick={() => {
                                                        // remove the theme
                                                        handleRemoval(index);
                                                    }}>
                                                    X
                                                </Button>
                                            </GridItem>
                                            {theme.vendorId === "" ? (
                                                "No Vendor"
                                            ) : (
                                                <div>{vendorList?.find((vendor) => vendor.id === theme.vendorId)?.name}</div>
                                            )}
                                            <Menu>
                                                {({ isOpen }) => (
                                                    <>
                                                        <MenuButton isActive={isOpen} as={Button} rightIcon={<ChevronDownIcon />}>
                                                            {isOpen ? "Done" : "Select Vendor"}
                                                        </MenuButton>
                                                        <MenuList>
                                                            {vendorList?.map((vendor, index) => (
                                                                <MenuItem
                                                                    key={index}
                                                                    onClick={() => {
                                                                        handleAdd(vendor.id, theme.name);
                                                                    }}>
                                                                    {vendor.name}
                                                                </MenuItem>
                                                            ))}
                                                        </MenuList>
                                                    </>
                                                )}
                                            </Menu>
                                        </Grid>
                                    </div>
                                </Box>
                            ))}
                        </Box>
                    </GridItem>
                    <GridItem colSpan={2}>
                        {/* show List of theme and have max height of 400px*/}
                        <Input
                            style={{ border: "1px solid blue", padding: "4px", maxWidth: "400px" }}
                            placeholder="Search vendors"
                            value={searchTerm[0].toString()}
                            onChange={(event) => setSearchTerm([event.target.value, searchTerm[1]])}
                        />
                        <Box style={{ border: "1px solid blue", padding: "4px", maxHeight: "430px", maxWidth: "400px", overflowY: "scroll" }}>
                            {themeList
                                ?.filter((theme) => theme.name.toLowerCase().includes(searchTerm[0].toLowerCase()))
                                .map((theme, index) => (
                                    <div>
                                        <Box>{theme.name}</Box>
                                    </div>
                                ))}
                        </Box>
                    </GridItem>

                    <Button
                        style={{ maxWidth: "100%" }}
                        onClick={() => {
                            handleSubmit();
                        }}>
                        Done
                    </Button>
                    <Button onClick={RefreshData}>Refresh</Button>
                </Grid>
            </div>
        </div>
    );
}
export default AddTheme;
