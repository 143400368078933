import { EndPoint } from "./DataEP";
import {toast, ToastContainer } from "react-toastify";
import { AssetDetails } from "../views/documents/Compliance/Compliance";
// debug
// const serviceurl = "https://localhost:7114/api";
// live
export async function SendLogIn(email: any, password: any) {
  let resp = null;
  try {
    const data = { email, password };
    const response = await fetch(EndPoint.Login, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    resp = response;
    if (response.ok) {
      const datas = await response.json();
      const modifiedData = JSON.parse(
        JSON.stringify(datas).replace(/"\s+|\s+"/g, '"')
      );
      return modifiedData;
    } else {
      resp = null;
      // throw new Error("Request failed with status: " + response.status);
    }
  } catch (err) {
    console.log("An error occurred fetching the data: " + err);
    return resp;
  }
  return resp;
}

export async function SendLogOff(token: any) {
  let resp = null;
  try {
    const response = await fetch(EndPoint.LogOff, {
      method: "POST",
      headers: { "Content-Type": "application/json", "authorization": token },
    });

    resp = response;
    if (response.ok) {
      const datas = await response.json();
      const modifiedData = JSON.parse(
        JSON.stringify(datas).replace(/"\s+|\s+"/g, '"')
      );
      return modifiedData;
    } else {
      // throw new Error("Request failed with status: " + response.status);
    }
  } catch (err) {
    console.log("An error occurred fetching the data: " + err);
    return resp;
  }
  return resp;
}
