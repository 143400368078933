import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    Input,
    ButtonGroup,
    Checkbox,
    useColorModeValue,
    Flex,
    Button,
    Center,
    Table,
    GridItem,
    Tbody,
    Grid,
    Td,
    Th,
    Thead,
    Tr,
    border,
    color,
} from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
import { finalURL } from "api/DataEP";
import routes from "routes";
import { VendorDetails, ThemeDetails, VendorList, ThemeList } from "./Compliance";
import { ChevronRightIcon } from "@chakra-ui/icons";

const AddVendorTheme = ({ setPageState }: { setPageState: React.Dispatch<React.SetStateAction<string>> }) => {
    const [vendorList, setVendorList] = useState<VendorDetails[] | null>(null);
    const [themeList, setThemeList] = useState<ThemeDetails[] | null>(null);
    const [userinfoData] = useState(JSON.parse(JSON.stringify(routes[0].userinfo).replace(/"\s+|\s+"/g, '"')));
    const token = userinfoData.token;
    useEffect(() => {
        VendorList(token, setVendorList);
        ThemeList(token, setThemeList);
    }, []);
    async function RefreshData() {
        setVendorList(null);
        setThemeList(null);
        await VendorList(token, setVendorList);
        await ThemeList(token, setThemeList);
    }

    const [theme, setTheme] = useState("");
    const [vendor, setVendor] = useState("");
    const [addedVendor, setAddedVendor] = useState<string[]>([]);
    const [addedTheme, setAddedTheme] = useState<string[]>([]);
    const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTheme(event.target.value);
    };
    function handleDone(setFunction: any) {
        if (setFunction === setVendor) {
            setAddedVendor([...addedVendor, vendor]);
        }
        if (setFunction === setTheme) {
            setAddedTheme([...addedTheme, theme]);
        }
    }
    const handleVendorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVendor(event.target.value);
    };

    const [searchTerm, setSearchTerm] = useState<String[]>(["", ""]);
    const handleSubmit = () => {
        let URLVendor = finalURL + "api/vendor";

        for (let i = 0; i < addedVendor.length; i++) {
            fetch(URLVendor, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    authorization: token,
                },
                body: JSON.stringify({ name: addedVendor[i] }),
            })
                .then((response) => response.json())

                .then((response) => {
                    if (response.success === true) {
                        toast.success("Vendor:" + addedVendor[i] + " added successfully");
                    } else {
                        toast.error("Vendor:" + addedVendor[i] + " ERROR:" + response.message);
                    }
                });
        }
    };
    const [fadingItems, setFadingItems] = useState<{ [key: number]: boolean }>({});

    // Existing useEffect and functions

    const handleRemoveItem = (index: number) => {
        // Mark the item as fading
        setFadingItems((prev) => ({ ...prev, [index]: true }));

        // Wait for the fade effect to complete before removing the item
        setTimeout(() => {
            setAddedVendor(addedVendor.filter((x, i) => i !== index));
            // Clean up the fadingItems state
            setFadingItems((prev) => {
                const newState = { ...prev };
                delete newState[index];
                return newState;
            });
        }, 500); // Duration of the fade effect
    };
    return (
        <div>
            <ToastContainer />
            <Button
                onClick={() => {
                    setPageState("home");
                }}>
                Home
            </Button>
            <ChevronRightIcon />
            <br />
            <br />

            <Grid templateColumns={"repeat(3, 1fr)"} gap={3}>
                <GridItem colSpan={1}>
                    <Text>Added Vendor</Text>

                    <Box>
                        {" "}
                        <Input
                            style={{ border: "1px solid blue", padding: "4px" }}
                            placeholder="Add Vendor"
                            type="text"
                            value={vendor}
                            // fontSize={"lg"}
                            onChange={handleVendorChange}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    if (vendor === "") {
                                        toast.error("Vendor Name cannot be empty");
                                    } else {
                                        handleDone(setVendor);
                                        setVendor("");
                                    }
                                }
                            }}
                        />
                        <br />
                    </Box>
                    <Box style={{ maxHeight: "250px", overflowY: "scroll" }}>
                        {addedVendor?.map((vendor, index) => (
                            <div
                                style={{
                                    border: fadingItems[index] ? "1px solid black" : "1px solid blue",
                                    padding: "4px",
                                    marginTop: "8px",
                                   
                                }}
                                key={index}>
                                <Flex justify={"space-between"}>
                                    <Text fontSize={"lg"} style={fadingItems[index] ? { opacity: 0, transition: "opacity 0.5s ease-out" } : {}}>
                                        {vendor}
                                    </Text>
                                    <Button
                                        style={{ alignContent: "end" }}
                                        onClick={() => {
                                            handleRemoveItem(index);
                                        }}>
                                        X
                                    </Button>
                                </Flex>
                            </div>
                        ))}
                    </Box>
                </GridItem>

                <GridItem colSpan={2} maxH="400px" maxW="400px" overflowY="auto">
                    <Box>Vendor List</Box>

                    <Box>
                        <Input
                            placeholder="Search vendors"
                            value={searchTerm[0].toString()}
                            onChange={(event) => setSearchTerm([event.target.value, searchTerm[1]])}
                        />
                        <Box style={{ maxHeight: "250px", overflowY: "scroll" }}>
                            {vendorList
                                ?.filter((vendor) => vendor.name.toLowerCase().includes(searchTerm[0].toLowerCase()))
                                .map((vendor, index) => (
                                    <div key={index} onClick={(e) => e.stopPropagation()}>
                                        <Text>{vendor.name}</Text>
                                    </div>
                                ))}
                        </Box>
                    </Box>
                </GridItem>
                <Button onClick={handleSubmit}>Submit</Button>
                <Button onClick={RefreshData}>Refresh</Button>
            </Grid>
        </div>
    );
};

export default AddVendorTheme;
