import React, { useEffect } from "react";
import { NavLink, Redirect, Route } from "react-router-dom";
// Chakra imports
import {
    Box,
    Button,
    Checkbox,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Icon,
    Input,
    InputGroup,
    InputRightElement,
    Text,
    useColorModeValue,
} from "@chakra-ui/react";
import { toast, ToastContainer } from "react-toastify";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import { SendLogIn } from "../../../api/service";
import routes from "routes";
import { userInfo } from "os";
import { useCookies } from "react-cookie";
import "react-toastify/dist/ReactToastify.css";
function SignIn() {
    <Input
        isRequired={true}
        variant="auth"
        fontSize="sm"
        ms={{ base: "0px", md: "0px" }}
        type="email"
        placeholder="email@simmmple.com"
        mb="24px"
        fontWeight="500"
        size="lg"
    />;
    // Chakra color mode

    const textColor = useColorModeValue("navy.700", "white");
    const textColorSecondary = "gray.400";
    const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
    const textColorBrand = useColorModeValue("brand.500", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    const googleBg = useColorModeValue("secondaryGray.300", "whiteAlpha.200");
    const googleText = useColorModeValue("navy.700", "white");
    const googleHover = useColorModeValue({ bg: "gray.200" }, { bg: "whiteAlpha.300" });
    const googleActive = useColorModeValue({ bg: "secondaryGray.300" }, { bg: "whiteAlpha.200" });

    const [show, setShow] = React.useState(false);
    const handleClick = () => {
        setShow(!show);
    };
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [loginError, setLoginError] = React.useState("");
    const [cookies, setCookie] = useCookies(["empData"]);
    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();

        const data = { email, password };
        let resp = await SendLogIn(email, password);
        if (resp.success === true) {
            window.location.href = "/documents";

            setCookie("empData", resp, { path: "/" });
        } else {
            toast.error(resp.message);
        }

        // useEffect(() => {
        //   removeCookie("empData", { path: "/" });
        // }, []);

        // console.log(resp);
        // localStorage.setItem("empData", resp);
        // localStorage.setItem("empRole", resp["role"]);
        // localStorage.setItem("empName", resp["name"]);
        // localStorage.setItem("useremail", resp["email"]);
        // localStorage.setItem("empPhone", resp["phone"]);
        // localStorage.setItem("token", resp["token"]);

        // routes.forEach(function (key) {
        //   console.log("userToken=" + key.userToken + " name=" + key.name);
        //   // todo push the token
        //   key.userinfo = resp;
        //   key.userToken = resp["token"];
        //   routes.push(key);

        //   console.log(routes);
        // });

        /*
    fetch(URL.Login, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.ok) {
          localStorage.setItem("useremail", email);
          return res.json(); // Parse the response body as JSON
        } else {
          throw new Error("Request failed with status: " + res.status);
        }
      })
      .then((data) => {

        // Check the success property in the response data
        if (data.success) {
          localStorage.setItem("empData", data);
          localStorage.setItem("empRole", data["role"]);
          localStorage.setItem("empName", data["name"]);
          localStorage.setItem("empPhone", data["phone"]);
          localStorage.setItem("empPhone", data["phone"]);
          localStorage.setItem("token", data["token"]);
          //window.location.href = "/rtl/rtl-default";

          if (data["role"] === "TECHNICIAN" || data["role"] ===  "SUPERVISOR"){
            window.location.href = "/el";
          } else {
            window.location.href = "/dashboard";
          }
          
        } else {
          console.log("Invalid username or password");
          console.log(data.success);
        }
      })
      .catch((err) => {
        setLoginError("An error occurred during login: " + err.message);
      });
    // Send the login information to your server for verification
    */
    };

    function sendLogin(e: React.FormEvent) {
        if (email === "" || password === "") {
            setLoginError("Please fill all fields");
            return;
        } else {
            //navigate to dashboard code:
            handleLogin(e);
        }
    }

    function updateToken(newtoke: any) {}

    return (
        <Flex
            w="100%"
            h="100%"
            maxW="100%"
            mx="auto"
            me="auto"
            mb="0px"
            mt="0px"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            background="transparent"
            px={{ base: "25px", md: "0px" }}>
            <ToastContainer />
            <Flex
                maxW={{ base: "100%", md: "max-content" }}
                w="100%"
                mx={{ base: "auto", lg: "0px" }}
                me="auto"
                h="100%"
                alignItems="start"
                justifyContent="center"
                mb={{ base: "30px", md: "60px" }}
                px={{ base: "25px", md: "0px" }}
                mt={{ base: "40px", md: "14vh" }}
                flexDirection="column">
                <Box me="auto">
                    <Heading color={textColor} fontSize="36px" mb="10px">
                        Sign In
                    </Heading>
                    <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
                        Enter your email and password to sign in!
                    </Text>
                </Box>
                <></>
                <Flex
                    zIndex="2"
                    direction="column"
                    w={{ base: "100%", md: "420px" }}
                    maxW="100%"
                    background="transparent"
                    borderRadius="15px"
                    mx={{ base: "auto", lg: "unset" }}
                    me="auto"
                    mb={{ base: "20px", md: "auto" }}>
                    <Flex align="center" mb="25px"></Flex>
                    <FormControl>
                        <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                            Email<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Input
                            isRequired={true}
                            variant="auth"
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="email"
                            placeholder="mail@simmmple.com"
                            mb="24px"
                            fontWeight="500"
                            size="lg"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                            Password<Text color={brandStars}>*</Text>
                        </FormLabel>
                        <InputGroup size="md">
                            <Input
                                isRequired={true}
                                fontSize="sm"
                                placeholder="Min. 8 characters"
                                mb="24px"
                                size="lg"
                                type={show ? "text" : "password"}
                                variant="auth"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <InputRightElement display="flex" alignItems="center" mt="4px">
                                <Icon
                                    color={textColorSecondary}
                                    _hover={{ cursor: "pointer" }}
                                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                                    onClick={handleClick}
                                />
                            </InputRightElement>
                        </InputGroup>
                        <Button fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px" onClick={(e) => sendLogin(e)}>
                            Sign In
                        </Button>
                    </FormControl>
                    <Flex flexDirection="column" justifyContent="center" alignItems="start" maxW="100%" mt="0px"></Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export default SignIn;

function removeCookie(arg0: string, arg1: { path: string }) {
    throw new Error("Function not implemented.");
}
/*
this is for the profile
{
  "email": "1",
  "role": "ADMIN",
  "employeeId": "2d90c086-f7cc-493a-8486-1107f2ba50ab",
  "name": "Quick Test",
  "photo": null,
  "phone": "999-999-9999",
  "token": "cb645bfe-5a4a-4d8d-bd62-8b8e7a132bfc",
  "success": true,
  "message": null
}            
*/
