import { useEffect, useState } from "react";
import { Id, toast, ToastContainer } from "react-toastify";
import { finalURL } from "api/DataEP";
import {
    Box,
    Flex,
    Table,
    Tbody,
    Td,
    useColorModeValue,
    Text,
    Th,
    Thead,
    Tr,
    Link,
    Button,
    Modal,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    ModalBody,
    ModalCloseButton,
    Checkbox,
} from "@chakra-ui/react";
import routes from "routes";
import { Document, Page } from "react-pdf";
import { get } from "http";
interface AssetDetails {
    serialNumber: string;
    asset: string;
    machineId: string | null;
    themeDataTrac: string;
    vendorDataTrac: string;
    localeId: string | null;
    mapX: number;
    mapY: number;
    bankPosition: string;
    installDate: string;
    lastServiceDate: string | null;
    meterUnit: string | null;
    gameType: string;
    gameGenre: string;
    multiGame: string | null;
    epromId: string;
    cabinetType: string;
    cabinetProfile: string;
    id: string;
    updatedDate: string;
    verifiedTheme: string;
    verifiedThemeId: string;
    verifiedVendor: string;
    verifiedVendorId: string;
}
interface ComplianceViewProps {
    targetAsset?: AssetDetails | null;
    assetNumber?: string | null;
    selectedAssets?: AssetDetails[] | null;
}
interface link {
    id: string;
    linkedDocId: string;
    resourceId: string;
    resourceType: string;
}

interface fileDetails {
    id: string;
    documentTypeId: string;
    fileName: string;
    filePath: string;
    dateUploaded: string;
    employeeIduploaded: string;
    note: string | null;
    md5hash: string;
    link: link;
    documentTypeName: string;
}
interface selectedSuggestionType {
    documentId: string;
}
interface suggestionType {
    linkedDocument: fileDetails;
}
const ComplianceView: React.FC<ComplianceViewProps> = ({ targetAsset }) => {
    const bg = useColorModeValue("white", "#1B254B");
    const [userinfoData] = useState(JSON.parse(JSON.stringify(routes[0].userinfo).replace(/"\s+|\s+"/g, '"')));
    const token = userinfoData.token;
    const [files, setFiles] = useState<fileDetails[] | null>(null);
    const [suggestions, setSuggestions] = useState<suggestionType[] | null>(null);
    async function getFiles() {
        let URL = finalURL + "api/linked-document/equipment?equipmentId=";
        URL += targetAsset.id;
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                authorization: token,
            },
        });
        if (response.ok) {
            const json = await response.json();
            if (json.items.length !== 0) {
                setFiles(json?.items);
            } else {
                toast.error("No files found");
            }
        }
    }

    async function deleteFile(id: string) {
        let URL = finalURL + "api/linked-document/link/equipment";
        const headers = {
            "Content-Type": "application/json",
            authorization: token,
        };

        try {
            const response = await fetch(URL, {
                method: "DELETE",
                headers: headers,
                body: JSON.stringify({ linkIds: [id] }),
            });
            if (response.ok) {
                toast.success("File deleted");

                if (targetAsset.id) {
                    await getFiles();
                }
            } else {
                toast.error("File not deleted");
            }
        } catch (error) {
            console.error("There was an error deleting the linked document", error);
        }
    }
    const [selectedAssetListResult, setselectedAssetListResult] = useState<AssetDetails[] | null>(null);

    async function GetSimilarAssets(themeId: string, vendorId: string) {
        let URL = finalURL + "api/compliance/equipment";
        if (vendorId === "" || vendorId === null) {
            URL = URL + "?themeid=" + themeId;
        }
        if (themeId === "" || themeId === null) {
            URL = URL + "?vendorid=" + vendorId;
        }
        if (vendorId !== "" && vendorId !== null && themeId !== "" && themeId !== null) {
            URL = URL + "?themeid=" + themeId + "&vendorid=" + vendorId;
        }
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });
        if (response.ok) {
            const data = await response.json();
            setselectedAssetListResult((x) => (x ? [...x, ...data.items] : [...data.items]));
            setselectedAssetListResult((prevState) => {
                if (prevState) {
                    const uniqueItems = Array.from(new Map(prevState.map((item) => [item.serialNumber, item])).values());
                    return uniqueItems;
                }
                return null;
            });
            //still has repeats

            // setselectedAssetListResult((prevState) => {
            //     if (prevState) {
            //         const uniqueItems = Array.from(new Map(prevState.map((item) => [item.serialNumber, item])).values());
            //         return uniqueItems;
            //     }
            //     return null;
            // });
        } else {
            toast.error("Failed to fetch similar assets");
        }
    }

    async function downloadFile(name: string, id: string) {
        let URLa = finalURL + "api/linked-document/download?linkedDocId=" + id;
        const response = await fetch(URLa, {
            method: "GET",
            headers: {
                authorization: token,
            },
        });
        if (response.ok) {
            //response is a blob
            const blob = await response.blob();
            //download the blob in new tab
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = name; // Set the desired filename
            a.click();
            URL.revokeObjectURL(url);
        }
    }
    useEffect(() => {
        // to check if targetAsset is defined
        setselectedAssetListResult(null);
        if (targetAsset.id) {
            getFiles();
        }
        GetSimilarAssets(targetAsset.verifiedThemeId, targetAsset.verifiedVendorId);
    }, []);

    async function linkSuggested() {
        // add targetAsset.id to the resourceId in bodys
        let res: { resourceId: string; documentId: string }[] = [];
        res = [];
        if (selectedSuggestion) {
            selectedSuggestion.forEach((suggestion) => {
                res.push({ resourceId: targetAsset.id, documentId: suggestion.documentId });
            });
        }
        let bodys = JSON.stringify(res);
        console.log(JSON.stringify(bodys));
        let URL = finalURL + "api/linked-document/link/equipment";
        let response = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: bodys,
        });
        if (response.ok) {
            toast.success("Suggestion linked");
            await getSuggestion();
        } else {
            toast.error("Suggestion not linked");
        }
    }
    async function linkSuggestedSimilar() {
        setselectedAssetListResult(null);
        await GetSimilarAssets(targetAsset.verifiedThemeId, targetAsset.verifiedVendorId);
        //res should use the selectedAssetListResult's assetid as resource id, and use the selectedSuggestion's documentId
        let res: { resourceId: string; documentId: string }[] = [];
        // remove repeated resourceId and documentId
        if (selectedAssetListResult) {
            selectedAssetListResult.forEach((asset) => {
                if (selectedSuggestion) {
                    selectedSuggestion.forEach((suggestion) => {
                        res.push({ resourceId: asset.id, documentId: suggestion.documentId });
                    });
                } else {
                    toast.error("No suggestion selected");
                }
            });
        } else {
            toast.error("No similar assets found");
        }

        let bodys = JSON.stringify(res);
        console.log(res);
        let URL = finalURL + "api/linked-document/link/equipment";
        let response = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: bodys,
        });
        if (response.ok) {
            toast.success("Suggestion linked");
            await getSuggestion();
        } else {
            toast.error("Suggestion not linked");
        }
        bodys = null;
    }
    const [selectedSuggestion, setSelectedSuggestion] = useState<selectedSuggestionType[] | null>(null);

    const [pdfData, setPdfData] = useState<Blob | null>(null);
    const [viewPDF, setViewPDF] = useState(false);
    const [currentFile, setCurrentFile] = useState<fileDetails | null>(null);
    const getSuggestion = async () => {
        let URLa = finalURL + "api/v2/suggestion/equipment/" + targetAsset?.id;
        const response = await fetch(URLa, {
            method: "GET",
            headers: {
                authorization: token,
            },
        });
        if (response.status === 200) {
            if (response !== null && response !== undefined) {
                const json = await response.json();
                setSuggestions(json);
            } else {
                toast.error("No suggestion found");
            }
        } else {
            toast.error("No suggestion found");
        }
    };
    const [suggestionClick, setShowSuggestionClick] = useState(false);

    const showSuggestion = async () => {
        setShowSuggestionClick(!suggestionClick);
        await getSuggestion();
    };
    const vewPDF = async (file: fileDetails) => {
        let URLa = finalURL + "api/linked-document/download?linkedDocId=" + file.id;
        const response = await fetch(URLa, {
            method: "GET",
            headers: {
                authorization: token,
            },
        });
        if (response.ok) {
            const blob = await response.blob();
            setCurrentFile(file); // Set the current file details
            const url = window.URL.createObjectURL(blob);
            const pdfUrl = URL.createObjectURL(blob);
            setPdfData(blob);
            if (pdfUrl) {
                setViewPDF(true);
            }
        }
    };
    const [numPages, setNumPages] = useState(0);
    const isAdmin = userinfoData.role;
    useEffect(() => {
        if (targetAsset && targetAsset.id) {
            if (suggestionClick) {
                getSuggestion();
            } else {
                getFiles();
            }
        }
    }, [suggestionClick]);

    return (
        <>
            <ToastContainer />
            {viewPDF && currentFile && (
                <Modal
                    isOpen={viewPDF}
                    onClose={() => {
                        setViewPDF(false);
                        setCurrentFile(null);
                    }}
                    size="3xl">
                    <ModalOverlay />
                    <ModalContent>
                        <ModalHeader>File Viewer</ModalHeader>
                        <ModalCloseButton />
                        <ModalBody style={{ maxHeight: "80vh", overflowY: "auto" }}>
                            {currentFile.fileName.endsWith(".pdf") ? (
                                <Document file={pdfData} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
                                    {Array.from({ length: numPages }, (_, index) => (
                                        <div style={{ marginBottom: "-10px" }}>
                                            <Page key={index} pageNumber={index + 1} renderTextLayer={false} renderAnnotationLayer={false} />
                                        </div>
                                    ))}
                                </Document>
                            ) : currentFile.fileName.match(/\.(png|jpg|jpeg)$/) ? (
                                <img src={URL.createObjectURL(pdfData)} alt="Document" style={{ maxWidth: "100%", height: "auto" }} />
                            ) : (
                                <p>Please download to view the file</p>
                            )}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            {(isAdmin === "ADMIN" || isAdmin === "MANAGER") && (
                <div>
                    {" "}
                    <Button
                        onClick={async () => {
                            await showSuggestion();
                        }}>
                        {suggestionClick ? "Show Current Files" : "Show Suggestion"}
                    </Button>
                </div>
            )}

            {suggestionClick && (
                <div>
                    The suggestions are:
                    <Table variant="simple" size="md" borderWidth="1px" borderColor="blue.200" bg={bg}>
                        <Thead>
                            <Tr>
                                <Th borderWidth="1px" borderColor="blue.200">
                                    File Type VENDOR
                                </Th>
                                <Th borderWidth="1px" borderColor="blue.200">
                                    File Name
                                </Th>
                                <Th borderWidth="1px" borderColor="blue.200">
                                    Upload Date
                                </Th>
                                <Th borderWidth="1px" borderColor="blue.200">
                                    View
                                </Th>
                                <Th borderWidth="1px" borderColor="blue.200">
                                    Confirm
                                </Th>
                                {/* <Th borderWidth="1px" borderColor="gray.200">
                            Delete
                        </Th> */}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {suggestions?.map((suggestion) => (
                                <Tr key={suggestion.linkedDocument.id}>
                                    <Td borderWidth="1px" borderColor="blue.200">
                                        {suggestion.linkedDocument.documentTypeName}
                                    </Td>
                                    <Td borderWidth="1px" borderColor="blue.200">
                                        {suggestion.linkedDocument.fileName}
                                    </Td>
                                    <Td borderWidth="1px" borderColor="blue.200">
                                        {suggestion.linkedDocument.dateUploaded}
                                    </Td>
                                    <Td borderWidth="1px" borderColor="blue.200">
                                        <Button
                                            onClick={async () => {
                                                await vewPDF(suggestion.linkedDocument);
                                            }}>
                                            View
                                        </Button>
                                    </Td>
                                    <Td borderWidth="1px" borderColor="blue.200">
                                        <Checkbox
                                            isInvalid
                                            _checked={{
                                                "& .chakra-checkbox__control": { background: "green" },
                                            }}
                                            size="lg"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    if (selectedSuggestion === null) {
                                                        setSelectedSuggestion([
                                                            {
                                                                documentId: suggestion.linkedDocument.id,
                                                            },
                                                        ]);
                                                    } else {
                                                        setSelectedSuggestion([
                                                            ...selectedSuggestion,
                                                            {
                                                                documentId: suggestion.linkedDocument.id,
                                                            },
                                                        ]);
                                                    }
                                                } else {
                                                    setSelectedSuggestion(
                                                        selectedSuggestion?.filter((item) => item.documentId !== suggestion.linkedDocument.id)
                                                    );
                                                }
                                            }}></Checkbox>
                                    </Td>
                                    {/* <Td borderWidth="1px" borderColor="gray.200">
                                <Button
                                    color={"red"}
                                    onClick={() => {
                                        deleteFile(file.link.id);
                                    }}>
                                    Delete
                                </Button>
                            </Td> */}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                    <Button onClick={linkSuggestedSimilar}> Link to all similar assets </Button>
                    <Button onClick={linkSuggested}> Link to only searched asset</Button>
                </div>
            )}
            {suggestionClick === false && (
                <div>
                    For asset {targetAsset.asset}, the files are:
                    <Table variant="simple" size="md" borderWidth="1px" borderColor="gray.200" bg={bg}>
                        <Thead>
                            <Tr>
                                <Th borderWidth="1px" borderColor="gray.200">
                                    File Type
                                </Th>
                                <Th borderWidth="1px" borderColor="gray.200">
                                    File Name
                                </Th>
                                <Th borderWidth="1px" borderColor="gray.200">
                                    Upload Date
                                </Th>
                                <Th borderWidth="1px" borderColor="gray.200">
                                    View
                                </Th>
                                <Th borderWidth="1px" borderColor="gray.200">
                                    Download
                                </Th>
                                {/* <Th borderWidth="1px" borderColor="gray.200">
                            Delete
                        </Th> */}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {files?.map((file) => (
                                <Tr key={file.id}>
                                    <Td borderWidth="1px" borderColor="gray.200">
                                        {file.documentTypeName}
                                    </Td>
                                    <Td borderWidth="1px" borderColor="gray.200">
                                        {file.fileName}
                                    </Td>
                                    <Td borderWidth="1px" borderColor="gray.200">
                                        {file.dateUploaded}
                                    </Td>
                                    <Td borderWidth="1px" borderColor="gray.200">
                                        <Button
                                            onClick={async () => {
                                                await vewPDF(file);
                                            }}>
                                            View
                                        </Button>
                                    </Td>
                                    <Td borderWidth="1px" borderColor="gray.200">
                                        <Button
                                            color={"green"}
                                            onClick={() => {
                                                downloadFile(file.fileName, file.id);
                                            }}>
                                            Download
                                        </Button>
                                    </Td>
                                    {/* <Td borderWidth="1px" borderColor="gray.200">
                                <Button
                                    color={"red"}
                                    onClick={() => {
                                        deleteFile(file.link.id);
                                    }}>
                                    Delete
                                </Button>
                            </Td> */}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </div>
            )}
        </>
    );
};
export default ComplianceView;
