import React, { EventHandler, useEffect, useState, ChangeEvent } from "react";
import { Box, Text, Input, ButtonGroup, Checkbox, useColorModeValue, Flex, Button, Center, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Dropzone, FileMosaic } from "@dropzone-ui/react";
import routes from "routes";
import { toast, ToastContainer } from "react-toastify";
import { response } from "express";
import ComplianceView from "./ComplianceView";
import { link } from "fs";
import { awsMode, finalURL } from "api/DataEP";
interface documentDetails {
    id: string;
    name: string;
    fileExtension: string;
    allowAll: boolean;
}

interface documentTypeList {
    items: documentDetails[];
    success: boolean;
    message: null | string;
}
export interface VendorDetails {
    id: string;
    name: string;
}
export interface ThemeDetails {
    id: string;
    link: null | string;
    name: string;
    notes: null;
    vendorId: string;
    vendorName: string;
}

export interface AssetDetails {
    serialNumber: string;
    asset: string;
    machineId: string | null;
    themeDataTrac: string;
    vendorDataTrac: string;
    localeId: string | null;
    mapX: number;
    mapY: number;
    bankPosition: string;
    installDate: string;
    lastServiceDate: string | null;
    meterUnit: string | null;
    gameType: string;
    gameGenre: string;
    multiGame: string | null;
    epromId: string;
    cabinetType: string;
    cabinetProfile: string;
    id: string;
    updatedDate: string;
    verifiedTheme: string;
    verifiedThemeId: string;
    verifiedVendor: string;
    verifiedVendorId: string;
}
export interface AssetView {
    asset: string;
    serialNumber: string;
    localId: string;
    theme: string;
    vendor: string;
}
export async function VendorList(token: string, setVendorList: React.Dispatch<React.SetStateAction<VendorDetails[] | null>>) {
    const URL = finalURL + "api/vendor";
    const response = await fetch(URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: token,
        },
    });
    if (response.ok) {
        const data = await response.json();
        setVendorList([...data.items]);
    } else {
        toast.error("Failed to fetch Vendor List");
    }
}
export async function ThemeList(token: string, setThemeList: React.Dispatch<React.SetStateAction<ThemeDetails[] | null>>) {
    const URL = finalURL + "api/theme";
    const response = await fetch(URL, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            authorization: token,
        },
    });
    if (response.ok) {
        const data = await response.json();
        setThemeList([...data.items]);
    } else {
        toast.error("Failed to fetch Vendor List");
    }
}

function Compliance({
    fromVendor = false,
    setPageState,
    editMode,
    assetSearch,
}: {
    fromVendor?: boolean;
    setPageState: React.Dispatch<React.SetStateAction<string>>;
    editMode: boolean;
    assetSearch?: string;
}) {
    const [userinfoData] = useState(JSON.parse(JSON.stringify(routes[0].userinfo).replace(/"\s+|\s+"/g, '"')));
    const token = userinfoData.token;
    const [targetAsset, setTargetAsset] = useState<AssetDetails[] | null>(null);
    const [innerPageState, setInnerPageState] = useState("ComplianceSearch");
    const [searchInput, setSearchInput] = useState("");
    const searchIconColor = useColorModeValue("gray.400", "white");
    const bg = useColorModeValue("white", "#1B254B");
    const inputBg = useColorModeValue("secondaryGray.300", "navy.900");
    const textBg = useColorModeValue("red", "yellow");
    const [uploadMode, setUploadMode] = useState(false);
    const [AssetViewData, setViewAssetData] = useState<AssetView[] | null>([]);
    const fetchAssetViewData = async () => {
        const response = await fetch(finalURL + "api/compliance/assets", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });

        if (!response.ok) {
            console.error("Error fetching data");
            return;
        }

        const assets: AssetView[] = await response.json();
        setViewAssetData(assets);
    };
    const filteredSearchData = AssetViewData.filter((asset) =>
        Object.values(asset).some((val) => String(val).toLowerCase().includes(searchInput.toLowerCase()))
    );

    const handleSearchRowClick = (asset: string) => {
        Search(asset);
    };
    async function Search(assetNumber: String) {
        setTargetAsset(null);
        let URL = finalURL + "api/compliance/equipment?";
        if (assetNumber === "" || assetNumber === null) {
            toast.error("Please enter a valid asset number");
        } else {
            if (vendorList === null) await VendorList(token, setVendorList);
            if (themeList === null) await ThemeList(token, setThemeList);
            URL = URL + "assetNumber=" + assetNumber;
            const response = await fetch(URL, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: token,
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.items[0].serialNumber) {
                }
                setTargetAsset([...data.items]);
                await GetSimilarAssets(data.items[0].verifiedThemeId, data.items[0].verifiedVendorId);
                setInnerPageState("ComplianceDetails");
            } else {
                toast.error("Failed to fetch data");
            }
        }
    }
    useEffect(() => {
        if (innerPageState === "ComplianceSearch") {
            if (assetSearch !== undefined && assetSearch !== null) {
                Search(assetSearch);
            }
            setselectedAssetListResult(null);
            setassetListResult(null);
            setSelectedThemeList(null);
            setSelectedVendorList(null);
            setFiles(null);
            setDocumentID(null);
        }
    }, [innerPageState]);
    const [selectedVendorList, setSelectedVendorList] = useState<VendorDetails[] | null>(null); //["vendor1", "vendor2"
    const [vendorList, setVendorList] = useState<VendorDetails[] | null>(null);

    const [selectedThemeList, setSelectedThemeList] = useState<ThemeDetails[] | null>(null);
    const [themeList, setThemeList] = useState<ThemeDetails[] | null>(null);
    const [and, setand] = useState(false);
    const [searchTheme, setSearchTheme] = useState("");
    const themeItemsPerPage = 5; // Set number of items per page
    const [currentThemePage, setCurrentThemePage] = useState(1);
    const fuzzySearch = (needle: string, haystack: string) => {
        const hlen = haystack.length;
        const nlen = needle.length;
        if (nlen > hlen) {
            return false;
        }
        if (nlen === hlen) {
            return needle.toLowerCase() === haystack.toLowerCase();
        }
        let j = 0;
        for (let i = 0; i < nlen; i++) {
            const nch = needle.charCodeAt(i);
            while (j < hlen) {
                if (haystack.charCodeAt(j) === nch) {
                    break;
                }
                j++;
            }
            if (j === hlen) {
                return false;
            }
        }
        return true;
    };
    const filteredThemeList = themeList ? themeList.filter((theme) => fuzzySearch(searchTheme.toLowerCase(), theme.name.toLowerCase())) : [];
    const totalThemePages = Math.ceil(filteredThemeList.length / themeItemsPerPage);
    const currentThemeItems = filteredThemeList.slice((currentThemePage - 1) * themeItemsPerPage, currentThemePage * themeItemsPerPage);

    let menuBg = useColorModeValue("white", "navy.800");
    type fileType = {
        file: any;
        multiple: boolean;
    };

    const handleFileChange = (event: ChangeEvent<HTMLInputElement>, id: string) => {
        const file = event.target.files?.[0] || null;
        setFiles({
            ...files,
            [id]: file,
        });
    };

    const [documentTypeLists, setdocumentTypeLists] = useState<documentTypeList | null>(null);
    useEffect(() => {
        if (assetSearch === undefined || assetSearch === null) {
            fetchAssetViewData();
            setUploadMode(editMode);
            GetDocumentType()
                .then((data) => {
                    setdocumentTypeLists(data);
                })
                .catch((err) => {
                    toast.error(err);
                });
        } else {
            Search(assetSearch);
        }
    }, []);
    const [assetListResult, setassetListResult] = useState<AssetDetails[] | null>(null);
    const [selectedAssetListResult, setselectedAssetListResult] = useState<AssetDetails[] | null>(null);
    const [files, setFiles] = useState<{ [keys: string]: File | null }>({});
    const [documentID, setDocumentID] = useState<string[] | null>([]);

    async function GetDocumentType(): Promise<documentTypeList> {
        const URL = finalURL + "api/document-type";
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            throw new Error("Failed to fetch data");
        }
    }
    async function SendFiles() {
        let uploadedDocumentID: string[] = [];
        for (const [key, value] of Object.entries(files)) {
            const URL = finalURL + "api/linked-document?docTypeId=" + key;
            let formData = new FormData();
            if (value) {
                formData.append("file", value, value.name);

                const response = await fetch(URL, {
                    method: "POST",
                    headers: {
                        authorization: token,
                    },
                    body: formData,
                });

                if (response.ok) {
                    const data = await response.json();
                    if (data.success) {
                        if (data.content.id !== null) {
                            uploadedDocumentID.push(data.content.id);
                            console.log("Uploaded files");
                            console.log(data.content.id);
                        }
                    }
                } else {
                    toast.error("Failed to upload file: " + value.name);
                }
            }
        }
        setDocumentID(uploadedDocumentID);
        if (uploadedDocumentID.length > 0) {
            await LinkAssets(uploadedDocumentID);
        }
    }
    function URLBuilder() {
        const initialURL: string = finalURL + "api/compliance/equipment?";
        let URL: string[] = [];

        const themePart = selectedThemeList && selectedThemeList.length > 0 ? selectedThemeList.map((theme) => "&themeid=" + theme.id).join("") : "";
        const vendorPart =
            selectedVendorList && selectedVendorList.length > 0 ? selectedVendorList.map((vendor) => "&vendorid=" + vendor.id).join("") : "";

        if (and) {
            URL[0] = themePart + vendorPart;
        } else {
            if (themePart) {
                URL.push(themePart);
            }
            if (vendorPart) {
                URL.push(vendorPart);
            }
        }

        URL = URL.map((url) => initialURL + url);

        return URL;
    }
    async function GetFilesForAsset(asset: string | number) {
        let URL = finalURL + "api/linked-document/equipment?equipmentId=" + asset;
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });
    }
    async function GetSimilarAssets(themeId: string, vendorId: string) {
        let URL = finalURL + "api/compliance/equipment";
        if (vendorId === "" || vendorId === null) {
            URL = URL + "?themeid=" + themeId;
        }
        if (themeId === "" || themeId === null) {
            URL = URL + "?vendorid=" + vendorId;
        }
        if (vendorId !== "" && vendorId !== null && themeId !== "" && themeId !== null) {
            URL = URL + "?themeid=" + themeId + "&vendorid=" + vendorId;
        }
        const response = await fetch(URL, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
        });
        if (response.ok) {
            const data = await response.json();
            setselectedAssetListResult((x) => (x ? [...x, ...data.items] : [...data.items]));
            setselectedAssetListResult((prevState) => {
                if (prevState) {
                    const uniqueItems = Array.from(new Map(prevState.map((item) => [item.serialNumber, item])).values());
                    return uniqueItems;
                }
                return null;
            });
        } else {
            toast.error("Failed to fetch similar assets");
        }
    }
    async function searchAssets() {
        setassetListResult(null);
        const URLs: string[] = URLBuilder();
        URLs.forEach(async (URL) => {
            const response = await fetch(URL, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    authorization: token,
                },
            });
            if (response.ok) {
                const data = await response.json();
                if (data.items.length === 0) {
                    toast.error("No results found");
                }
                setassetListResult((x) => (x ? [...x, ...data.items] : [...data.items]));
                setassetListResult((prevState) => {
                    if (prevState) {
                        const uniqueItems = Array.from(new Map(prevState.map((item) => [item.serialNumber, item])).values());
                        return uniqueItems;
                    }
                    return null;
                });
            } else {
                toast.error("Failed to fetch Vendor List");
            }
        });
    }

    const LinkAssets = async (uploadedDocumentID: string[]) => {
        const URL = finalURL + "api/linked-document/link/equipment";

        const linkedAssets: Array<{ documentId: string; resourceId: string }> = [];
        const uniquePairs = new Set<string>();

        for (const docId of uploadedDocumentID) {
            for (const asset of selectedAssetListResult) {
                const uniqueId = `${docId}-${asset.id}`;
                if (!uniquePairs.has(uniqueId)) {
                    uniquePairs.add(uniqueId);
                    linkedAssets.push({
                        documentId: docId,
                        resourceId: asset.id,
                    });
                }
            }
        }

        const jsonPayload = JSON.stringify(linkedAssets);
        let response = await fetch(URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                authorization: token,
            },
            body: jsonPayload,
        });
        if (response.ok) {
            setInnerPageState("ComplianceSearch");
            toast.success("Successfully linked assets");
        } else {
            toast.error("Failed to submit assets");
        }
    };

    return (
        <>
            <ToastContainer />

            {innerPageState === "ComplianceSearch" && (
                <>
                    <Box padding="20px">
                        <Button
                            onClick={() => {
                                setPageState("home");
                            }}>
                            Document Home
                        </Button>
                        <ChevronRightIcon />
                        <Button
                            onClick={() => {
                                setInnerPageState("ComplianceSearch");
                            }}>
                            Search
                        </Button>
                        <ChevronRightIcon />
                    </Box>
                    <Input placeholder="Search" value={searchInput} onChange={(event) => setSearchInput(event.target.value)} bg={bg} />
                    <Box bg={bg}>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Asset</Th>
                                    <Th>Serial Number</Th>
                                    <Th>Local ID</Th>
                                    <Th>Theme</Th>
                                    <Th>Vendor</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {filteredSearchData.map((asset) => (
                                    <Tr key={asset.asset} onClick={() => handleSearchRowClick(asset.asset)}>
                                        <Td>{asset.asset}</Td>
                                        <Td>{asset.serialNumber}</Td>
                                        <Td>{asset.localId}</Td>
                                        <Td>{asset.theme}</Td>
                                        <Td>{asset.vendor}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                </>
            )}
            {innerPageState === "ComplianceDetails" && (
                <>
                    {fromVendor ? (
                        <></>
                    ) : (
                        <>
                            <Box padding="20px">
                                <Button
                                    onClick={() => {
                                        setPageState("home");
                                    }}>
                                    Document Home
                                </Button>
                                <ChevronRightIcon />
                                <Button
                                    onClick={() => {
                                        setInnerPageState("ComplianceSearch");
                                    }}>
                                    Search
                                </Button>
                                <ChevronRightIcon />
                            </Box>
                            <Button>{uploadMode ? "Upload" : "View"}</Button>
                        </>
                    )}
                    {editMode ? (
                        <Box alignContent={"center"} justifyContent="center" display="flex" alignItems="center">
                            <Button
                                onClick={() => {
                                    setUploadMode(!uploadMode);
                                }}
                                colorScheme="teal">
                                {uploadMode ? "Switch to View mode" : "Switch to Upload mode"}
                            </Button>
                        </Box>
                    ) : (
                        <></>
                    )}
                    {uploadMode ? (
                        <>
                            <Text>Asset Number: {targetAsset?.[0].asset}</Text>
                            <Text>Theme: {targetAsset?.[0].verifiedTheme}</Text>
                            <Text>Vendor: {targetAsset?.[0].verifiedVendor}</Text>
                            <Flex justifyContent={"flex-start"}>
                                <Box p={0.5} shadow="md" borderWidth="1px" textAlign={"center"}>
                                    {documentTypeLists?.items.map((item, index) => (
                                        <div key={index}>
                                            <Box p={5} shadow="md" borderWidth="1px" textAlign={"center"}>
                                                <Text mb={2}>
                                                    {item.name} Allowed Extensions: {item.fileExtension}
                                                </Text>
                                                <Input
                                                    type="file"
                                                    accept={item.allowAll ? undefined : item.fileExtension}
                                                    onChange={(e) => handleFileChange(e, item.id)}
                                                />
                                            </Box>
                                        </div>
                                    ))}
                                </Box>
                                <Box p={5} shadow="md" borderWidth="1px" textAlign={"justify"}>
                                    <Menu closeOnSelect={false}>
                                        <MenuButton as={Button}>Select Vendors</MenuButton>
                                        <MenuList>
                                            {vendorList?.map((vendor, index) => (
                                                <MenuItem key={index} onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        isChecked={selectedVendorList?.some((item) => item.id === vendor.id)}
                                                        onChange={() => {
                                                            setSelectedVendorList((prev) =>
                                                                prev
                                                                    ? prev.some((item) => item.id === vendor.id)
                                                                        ? prev.filter((item) => item.id !== vendor.id)
                                                                        : [...prev, vendor]
                                                                    : [vendor]
                                                            );
                                                        }}>
                                                        {vendor.name}
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                        </MenuList>
                                    </Menu>
                                    <Menu closeOnSelect={false}>
                                        <MenuButton as={Button}>Select Themes</MenuButton>
                                        <MenuList>
                                            <Input placeholder="Search themes" onChange={(e) => setSearchTheme(e.target.value)} />
                                            {currentThemeItems.map((theme, index) => (
                                                <MenuItem key={index} onClick={(e) => e.stopPropagation()}>
                                                    <Checkbox
                                                        isChecked={selectedThemeList?.some((item) => item.id === theme.id)}
                                                        onChange={() => {
                                                            setSelectedThemeList((prev) =>
                                                                prev
                                                                    ? prev.some((item) => item.id === theme.id)
                                                                        ? prev.filter((item) => item.id !== theme.id)
                                                                        : [...prev, theme]
                                                                    : [theme]
                                                            );
                                                        }}>
                                                        {theme.name}
                                                    </Checkbox>
                                                </MenuItem>
                                            ))}
                                            <ButtonGroup>
                                                <Button isDisabled={currentThemePage === 1} onClick={() => setCurrentThemePage((prev) => prev - 1)}>
                                                    Previous
                                                </Button>
                                                <Button
                                                    isDisabled={currentThemePage === totalThemePages}
                                                    onClick={() => setCurrentThemePage((prev) => prev + 1)}>
                                                    Next
                                                </Button>
                                            </ButtonGroup>
                                        </MenuList>
                                    </Menu>
                                    <div>
                                        <Button
                                            onClick={() => {
                                                setand(!and);
                                            }}>
                                            {and ? "AND" : "OR"}
                                        </Button>
                                    </div>

                                    <Button onClick={searchAssets}>Search</Button>
                                    <Button
                                        onClick={() => {
                                            setassetListResult(null);
                                            GetSimilarAssets(targetAsset?.[0].verifiedThemeId, targetAsset?.[0].verifiedVendorId);
                                        }}>
                                        Select Similar Assets
                                    </Button>
                                    <Button
                                        onClick={() => {
                                            setassetListResult(null);
                                            setselectedAssetListResult(targetAsset ? [targetAsset[0]] : []);
                                        }}>
                                        Select only this asset
                                    </Button>
                                    {assetListResult ? (
                                        <Box p={5} shadow="md" borderWidth="1px" textAlign={"justify"} maxHeight={655} overflowY="auto">
                                            <Text color={textBg}>Total Assets: {assetListResult ? assetListResult.length : 0}</Text>
                                            <Checkbox
                                                isChecked={selectedAssetListResult?.length === assetListResult?.length}
                                                onChange={() => {
                                                    setselectedAssetListResult((prev) =>
                                                        prev
                                                            ? prev.length === assetListResult?.length
                                                                ? []
                                                                : [...assetListResult]
                                                            : [...assetListResult]
                                                    );
                                                }}>
                                                Select All
                                            </Checkbox>

                                            {assetListResult
                                                ? assetListResult?.map((asset, index) => (
                                                      <div key={index}>
                                                          <Checkbox
                                                              isChecked={selectedAssetListResult?.some((item) => item.id === asset.id)}
                                                              onChange={() => {
                                                                  setselectedAssetListResult((prev) =>
                                                                      prev
                                                                          ? prev.some((item) => item.id === asset.id)
                                                                              ? prev.filter((item) => item.id !== asset.id)
                                                                              : [...prev, asset]
                                                                          : [asset]
                                                                  );
                                                              }}>
                                                              <Text mb={2}>
                                                                  {asset.asset} {asset.verifiedVendor} {asset.verifiedTheme}
                                                              </Text>
                                                          </Checkbox>
                                                      </div>
                                                  ))
                                                : null}
                                        </Box>
                                    ) : null}
                                </Box>

                                {selectedAssetListResult ? (
                                    <Box p={5} shadow="md" borderWidth="1px" textAlign={"justify"} maxHeight={820} overflowY="auto">
                                        <Text color={textBg}>Total Selected Assets: {selectedAssetListResult.length}</Text>
                                        {selectedAssetListResult?.map((asset, index) => (
                                            <div key={index}>
                                                <Checkbox
                                                    isChecked={selectedAssetListResult?.some((item) => item.id === asset.id)}
                                                    onChange={() => {
                                                        setselectedAssetListResult((prev) =>
                                                            prev
                                                                ? prev.some((item) => item.id === asset.id)
                                                                    ? prev.filter((item) => item.id !== asset.id)
                                                                    : [...prev, asset]
                                                                : [asset]
                                                        );
                                                    }}>
                                                    <Flex justifyContent={"space-evenly"}>
                                                        {asset.asset} {asset.verifiedVendor} {asset.verifiedTheme}
                                                    </Flex>
                                                </Checkbox>
                                            </div>
                                        ))}
                                    </Box>
                                ) : (
                                    ""
                                )}
                            </Flex>
                            <Box p={5} maxWidth={100}>
                                <Button onClick={SendFiles}>Submit Files</Button>
                            </Box>
                        </>
                    ) : (
                        <>
                            {targetAsset != null ? (
                                <>
                                    <ComplianceView targetAsset={targetAsset[0]} />
                                </>
                            ) : (
                                "Please wait"
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
}
export default Compliance;
